import React, { useState, useEffect, useContext } from "react";
import { Navigate, useNavigate } from "react-router-dom";
import { useOktaAuth } from "@okta/okta-react";
import { Box, CircularProgress } from "@mui/material";
import { Context } from "../context/Context";
import jwt_decode from "jwt-decode";
import Api from "../services/Api";
import { cookieGetter } from "../helpers/cookieGetter";
import { cookieEraser } from "../helpers/cookieEraser";

const classes = {
	page: {
		width: "100%",
		height: "100vh",
		display: "flex",
		justifyContent: "center",
		alignItems: "center",
		flexDirection: "column",
	},
};

const Login = ({ config }) => {
	const { oktaAuth, authState } = useOktaAuth();
	const [auth, setAuth] = useState(false);
	const [isGroup, setIsGroup] = useState(false);
	const { setSecondaryEmail } = useContext(Context);
	const { cid, pa_path, pa_query } = cookieGetter();
	const userEmail = process.env.REACT_APP_TENANT_ID + ".ontario.ca";
	const navigate = useNavigate();

	useEffect(() => {
		if (!cid || !pa_path) {
			return (window.location.href = process.env.REACT_APP_BASE_URL);
		}
		getSession();
	}, []);

	useEffect(() => {
		async function assign() {
			if (authState && isGroup) {
				try {
					await Api.assignGroup(authState.accessToken.accessToken);

					if (!cid || cid == "null") {
						let redirectUrlWithParams = process.env.REACT_APP_BASE_URL;
						// if (state.sessionToken) {
						// 	redirectUrlWithParams =
						// 		process.env.REACT_APP_BASE_URL +
						// 		"/login/sessionCookieRedirect?token=" +
						// 		state.sessionToken +
						// 		"&redirectUrl=" +
						// 		process.env.REACT_APP_BASE_URL;
						// }
						sessionStorage.clear();
						cookieEraser();
						return (window.location.href = redirectUrlWithParams);
					}
					if (pa_path && pa_path != "null") {
						let redirectUrlWithParams =
							process.env.REACT_APP_BASE_URL +
							pa_path +
							(pa_query && pa_query != "null" ? "?" + pa_query : "");
						sessionStorage.clear();
						cookieEraser();
						return (window.location.href = redirectUrlWithParams);
					}
				} catch (err) {
					console.error(err);
				}
				cookieEraser();
				return (window.location.href = process.env.REACT_APP_BASE_URL);
			}
		}
		assign();
	}, [authState, isGroup]);

	const getSession = async () => {
		try {
			/* Checking if there is an Active session */
			const session = await oktaAuth.session.get();
			// Check pa_path cookie & cid
			if (session.status === "ACTIVE") {
				/* Setting tokens for the app */
				let updatedTokens = await oktaAuth.token.getWithoutPrompt({
					responseType: "id_token",
				});
				oktaAuth.tokenManager.setTokens(updatedTokens.tokens);

				/* Ckecking idToken to have "secondEmail" prop */
				let idToken = jwt_decode(updatedTokens.tokens.idToken.idToken);
				if ("secondEmail" in idToken) {
					setSecondaryEmail(idToken.secondEmail);
				}

				/* */
				/* Checking if user's email is NOT a random SecureKey email */
				if (session.login.split("@")[1] != userEmail) {
					/* Check if idToken has "userGroups" AND it's value includes REACT_APP_USER_GROUP */
					if ("userGroups" in idToken) {
						if (
							Object.values(idToken.userGroups).includes(
								process.env.REACT_APP_USER_GROUP
							)
						) {
							oktaAuth.tokenManager.clear();
							cookieEraser();
							// const { cid, pa_path, pa_query } = cookieGetter();
							if (!cid || cid == "null") {
								let redirectUrlWithParams = process.env.REACT_APP_BASE_URL;
								// if (state.sessionToken) {
								// 	redirectUrlWithParams =
								// 		process.env.REACT_APP_BASE_URL +
								// 		"/login/sessionCookieRedirect?token=" +
								// 		state.sessionToken +
								// 		"&redirectUrl=" +
								// 		process.env.REACT_APP_BASE_URL;
								// }
								sessionStorage.clear();
								// cookieEraser();
								return (window.location.href = redirectUrlWithParams);
							}
							if (pa_path && pa_path != "null") {
								let redirectUrlWithParams =
									process.env.REACT_APP_BASE_URL +
									pa_path +
									(pa_query && pa_query != "null" ? "?" + pa_query : "");
								sessionStorage.clear();
								// cookieEraser();
								return (window.location.href = redirectUrlWithParams);
							}
							// cookieEraser();
							return (window.location.href = process.env.REACT_APP_BASE_URL);
						}
					}
					return setIsGroup(true);
				}
				setAuth(true);
			} else {
				/* If No active session exists */
				oktaAuth.tokenManager.clear();
				window.location.href = process.env.REACT_APP_BASE_URL;
			}
		} catch (err) {
			navigate("/error");
		}
	};

	if (!authState || !authState?.isAuthenticated) {
		return (
			<Box sx={classes.page}>
				<CircularProgress />
			</Box>
		);
	}

	return (
		authState.isAuthenticated &&
		auth && (
			<Navigate
				to={{
					pathname: "/profile",
				}}
				replace
			/>
		)
	);
};
export default Login;
