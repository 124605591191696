import { AppBar, Button, Toolbar, useMediaQuery } from "@mui/material";
import desktopLogo from "../../img/ontario-logo-desktop-1.svg";
import mobileLogo from "../../img/ontario-logo-mobile-1.svg";
import text from "../../data/text";
import { useContext } from "react";
import { Context } from "../../context/Context";

const classes = {
	langButton: {
		color: "#fff",
		fontWeight: "600",
		fontSize: "1.125rem",
		marginRight: "4rem",
		"&:hover": {
			textDecoration: "underline",
		},
		[`@media (max-width: 73em)`]: {
			marginRight: "0rem",
		},
	},
	logoContainer: {
		width: "180px",
		height: "46px",
	},
	navbar: {
		height: "5.75rem",
		display: "flex",
		justifyContent: "center",
	},
	toolbar: {
		display: "flex",
		justifyContent: "space-between",
		paddingLeft: "4rem",
		[`@media (max-width: 73em)`]: {
			paddingLeft: "1rem",
		},
	},
};

function Navbar(props) {
	const { locale, setLocale } = useContext(Context);
	const isMobile = useMediaQuery("(max-width:40em)");

	const changeLanguage = () => {
		setLocale(locale === "en" ? "fr" : "en");
	};

	return (
		<AppBar elevation={0} sx={classes.navbar} position="static">
			<Toolbar sx={classes.toolbar}>
				<a href="http://www.ontario.ca/" style={classes.logoContainer}>
					<img
						alt="Government of Ontario"
						src={isMobile ? mobileLogo : desktopLogo}
					/>
				</a>
				<Button onClick={changeLanguage} sx={classes.langButton}>
					{locale === "en"
						? isMobile
							? text.header.french.mobileLanguage
							: text.header.french.language
						: isMobile
						? text.header.english.mobileLanguage
						: text.header.english.language}
				</Button>
			</Toolbar>
		</AppBar>
	);
}

export default Navbar;
