import { useContext, useState } from "react";
import { useNavigate } from "react-router-dom";
import {
	Grid,
	TextField,
	Button,
	Box,
	Typography,
	IconButton,
	Collapse,
	CircularProgress,
	Dialog,
	DialogTitle,
	Divider,
	DialogContent,
} from "@mui/material";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import CloseIcon from "@mui/icons-material/Close";
import Error from "../common/Error";
import { Context } from "../../context/Context";
import VisibilityIcon from "@mui/icons-material/Visibility";
import VisibilityOffIcon from "@mui/icons-material/VisibilityOff";
import { StateContext } from "../../App";
import { useOktaAuth } from "@okta/okta-react";
import Api from "../../services/Api";
import text from "../../data/text";

const classes = {
	/* Main components styling */
	arrowIcon: {
		fontSize: "17px",
		marginRight: 0.5,
	},
	backButton: {
		marginTop: 1,
		width: "fit-content",
		padding: "4px",
	},
	buttonProgress: {
		color: "#fff",
	},
	collapse: {
		marginBottom: 1.5,
	},
	form: {
		marginTop: 1,
		position: "relative",
	},
	header: {
		fontSize: "18px",
		lineHeight: "1.56",
		fontWeight: "700",
		spacing: "0.03rem",
		color: "systemBlack.main",
		margin: "0px auto 10px",
		width: "fit-content",
		textAlign: "center",
	},
	hint: {
		marginBottom: 1,
	},
	showPassword: {
		position: "absolute",
		top: "29px",
		right: "3px",
		zIndex: "2",
		padding: "6px",
	},
	textInput: {
		width: "100%",
		height: "48px",
		marginTop: 1,
		marginBottom: 1,
	},
	updateButton: {
		width: "100%",
	},
	/* Help button & modal styling */
	closeButton: {
		position: "absolute",
		top: "24px",
		right: "24px",
	},
	dialogContent: {
		padding: "0 4% 4% 4%",
	},
	dialogTitle: {
		position: "relative",
		padding: "4%",
		paddingBottom: "2%",
	},
	divider: {
		height: "4px",
		width: "92%",
		margin: "auto",
	},
	helpButtonBox: {
		marginTop: "20px",
	},
	label: {
		fontWeight: "700",
	},
	modalHeader: {
		fontSize: "1.75rem",
		letterSpacing: ".02rem",
		lineHeight: "1.39",
		marginBottom: "1rem",
		fontWeight: "700",
	},
	modalSubHeader: {
		fontSize: "1.5rem",
		letterSpacing: ".03rem",
		lineHeight: "1.5",
		fontWeight: "700",
	},
	questionBox: {
		marginBottom: "20px",
	},
	step: {
		marginBottom: "10px",
	},
	stepsList: {
		padding: "0 0 0 25px",
		margin: "0",
	},
	title: {
		fontWeight: "900",
		fontSize: "22px",
	},
};

function Help() {
	const [showHelp, setShowHelp] = useState(false);
	const { locale } = useContext(Context);
	const language = locale == "fr" ? "french" : "english";

	const steps = text.content[language].passwordHelp.steps.map((s) => {
		return <li style={classes.step}>{s}</li>;
	});

	return (
		<Box sx={classes.helpButtonBox}>
			<Button onClick={() => setShowHelp(true)}>
				{text.content[language].passwordForm.hint}
			</Button>
			<Dialog
				open={showHelp}
				onClose={() => setShowHelp(false)}
				fullWidth={true}
				maxWidth={"md"}
				scroll="body"
			>
				<DialogTitle sx={classes.dialogTitle}>
					<img
						src="https://op3static.oktacdn.com/fs/bco/1/fs0ikh3u6aus6gh0j1d6"
						alt="Ontario"
					/>
					<Typography variant="h3" sx={classes.modalHeader}>
						{text.terms[language].header}
					</Typography>
					<Typography variant="h4" sx={classes.modalSubHeader}>
						{text.content[language].passwordHelp.title}
					</Typography>
					<IconButton
						size="small"
						sx={classes.closeButton}
						onClick={() => setShowHelp(false)}
						aria-label="Close Terms"
					>
						<CloseIcon fontSize="small" />
					</IconButton>
				</DialogTitle>
				<DialogContent sx={classes.dialogContent}>
					<ul style={classes.stepsList}>{steps}</ul>
				</DialogContent>
			</Dialog>
		</Box>
	);
}

function SKPassword(props) {
	const [password, setPassword] = useState("");
	const [error, setError] = useState({ state: false, text: "" });
	const [loading, setLoading] = useState(false);
	const [passwordShown, setPasswordShown] = useState(false);
	const { locale } = useContext(Context);
	const language = locale == "fr" ? "french" : "english";
	const { state, dispatch } = useContext(StateContext);
	const { authState } = useOktaAuth();
	const navigate = useNavigate();

	const handleSubmit = async (e) => {
		e.preventDefault();
		if (loading) return;
		if (!password || password.length < 1) {
			return setError({ state: true, text: "error8" });
		}
		setLoading(true);
		try {
			let response = await Api.sendPassword(authState.accessToken.accessToken, {
				email: state.userEmail,
				password: password,
			});
			dispatch({
				type: "SET_SESSION_TOKEN",
				payload: response.sessionToken,
			});
			dispatch({ type: "CHANGE_SCREEN", payload: "SS" });
		} catch (err) {
			setLoading(false);
			if (err.response && err.response.status == 401) {
				return navigate("/error");
			}
			if (err.response && err.response.data.errorCode == 1) {
				return setError({
					state: true,
					text: "error6",
				});
			} else if (err.response && err.response.data.errorCode == 2) {
				return setError({
					state: true,
					text: "error7",
				});
			} else if (err.response && err.response.data.errorCode == 3) {
				return setError({
					state: true,
					text: "error5",
				});
			} else if (err.response && err.response.data.errorCode == 7) {
				return setError({
					state: true,
					text: "error3",
				});
			}
			return setError({
				state: true,
				text: "error3",
			});
		}
	};

	const handleInput = (e) => {
		error.state && setError({ state: false, text: "" });
		setPassword(e.target.value);
	};

	const handleShowPassword = () => {
		setPasswordShown(!passwordShown);
		document.getElementById("password-input").type = passwordShown
			? "password"
			: "text";
	};

	return (
		<Grid direction="column" container>
			<Collapse in={error.state} sx={classes.collapse}>
				<Error error={error} />
			</Collapse>
			<Typography sx={classes.header} variant="h1">
				{text.content[language].passwordForm.title}
			</Typography>
			<Typography sx={classes.hint}>
				{text.content[language].passwordForm.hint1}
			</Typography>
			<form onSubmit={handleSubmit} style={classes.form}>
				<label htmlFor="password-input" style={classes.label}>
					{text.content[language].passwordForm.label}
				</label>
				<TextField
					id="password-input"
					//aria-describedby="otp-helper-text"
					variant="outlined"
					size="small"
					type="password"
					name="password"
					sx={classes.textInput}
					onChange={handleInput}
					value={password}
				/>

				<IconButton
					sx={classes.showPassword}
					onClick={handleShowPassword}
					aria-label="Show Password"
				>
					{passwordShown ? <VisibilityOffIcon /> : <VisibilityIcon />}
				</IconButton>

				<Button
					variant="contained"
					color="secondary"
					sx={classes.updateButton}
					onClick={handleSubmit}
				>
					{!loading ? (
						text.content[language].passwordForm.button
					) : (
						<CircularProgress size={24} sx={classes.buttonProgress} />
					)}
				</Button>
			</form>
			<Help />
			<Button
				onClick={() =>
					dispatch({
						type: "CHANGE_SCREEN",
						payload: "EPS",
					})
				}
				sx={classes.backButton}
			>
				<ArrowBackIcon sx={classes.arrowIcon} />{" "}
				{text.content[language].passwordForm.returnButton}
			</Button>
		</Grid>
	);
}

export default SKPassword;
