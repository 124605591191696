import { Grid, Typography } from "@mui/material";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";

const classes = {
	health: {
		height: "100vh",
		width: "100vw",
	},
	icon: {
		color: "green.main",
		fontSize: "38px",
		marginRight: "10px",
	},
	message: {
		fontSize: "28px",
	},
	status: {
		fontSize: "52px",
	},
};

function Health(props) {
	return (
		<Grid
			container
			justifyContent="center"
			alignItems="center"
			direction="column"
			sx={classes.health}
		>
			<Typography sx={classes.status}>Status: </Typography>
			<Grid
				item
				container
				direction="row"
				justifyContent="center"
				alignItems="center"
				sx={classes.messageBox}
			>
				<CheckCircleIcon sx={classes.icon} />
				<Typography sx={classes.message}>System operational</Typography>
			</Grid>
		</Grid>
	);
}

export default Health;
