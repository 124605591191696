import { Grid, Link, useMediaQuery } from "@mui/material";
import { useContext, useEffect, useState } from "react";
import { Context } from "../../context/Context";
import text from "../../data/text";
import Contact from "./ContactUs";

const classes = {
	footer: {
		backgroundColor: "#ededed",
		paddingTop: "2.5rem",
		paddingBottom: "2.5rem",
		marginTop: "auto",
	},
	linksList: {
		width: "fit-content",
		marginBottom: "1rem",
		marginLeft: "3.5rem",
		"& a": {
			marginRight: "3rem",
			marginBottom: "0.25rem",
			textDecoration: "none",
			color: "link.main",
			"&:hover": {
				color: "linkHover.main",
				textDecoration: "underline",
			},
		},
		[`@media (max-width: 40em)`]: {
			marginLeft: "1rem",
			marginBottom: "0rem",
			"& a": {
				marginRight: "0",
				marginBottom: "1rem",
				marginLeft: "0",
				textDecoration: "none",
				color: "link.main",
				"&:hover": {
					color: "linkHover.main",
					textDecoration: "underline",
				},
			},
		},
	},
	printerLink: {
		marginLeft: "3.5rem",
		textDecoration: "none",
		color: "link.main",
		"&:hover": {
			color: "linkHover.main",
			textDecoration: "underline",
		},
		[`@media (max-width: 40em)`]: {
			marginLeft: "1rem",
		},
	},
};
//CHECK MUI docs if can use makeStyles or similar
//otherwise rewrite styling for native html/jsx components (rg ul, li ,a, label, span, h1...)
function Footer(props) {
	const { locale } = useContext(Context);
	const language = locale === "fr" ? "french" : "english";
	const [showModal, setShowModal] = useState(false);
	const [helpLink, setHelpLink] = useState("");
	const isMobile = useMediaQuery("(max-width: 40em)");

	const currentDate = new Date();
	const currentYear = currentDate.getFullYear().toString();
	const yearsString = "2012–" + currentYear.substring(2, 4);

	useEffect(() => {
		let url = window.location.origin;
		url = `${url}/help${locale == "fr" ? "?lang=fr" : ""}`;
		setHelpLink(url);
	}, [locale]);

	const openModal = (e) => {
		e.preventDefault();
		setShowModal(true);
	};

	const closeModal = () => {
		setShowModal(false);
	};

	return (
		<Grid container direction="column" sx={classes.footer}>
			<Grid
				item
				container
				direction={isMobile ? "column" : "row"}
				sx={classes.linksList}
			>
				<Link
					href={text.footer[language].accessibility.url}
					target="_blank"
					rel="noopener"
				>
					{text.footer[language].accessibility.title}
				</Link>
				<Link
					href={text.footer[language].privacy.url}
					target="_blank"
					rel="noopener"
				>
					{text.footer[language].privacy.title}
				</Link>
				<Link
					href="https://www.ontario.ca/feedback/contact-us"
					onClick={openModal}
					target="_blank"
					rel="noopener"
				>
					{text.footer[language].contact}
				</Link>
				<Link href={helpLink} target="_blank" rel="noopener">
					{text.footer[language].help}
				</Link>
			</Grid>
			<Grid item sx={{ width: "fit-content" }}>
				<Link
					href={text.footer[language].copyright.url}
					target="_blank"
					rel="noopener"
					sx={classes.printerLink}
				>
					{text.footer[language].copyright.title + yearsString}
				</Link>
			</Grid>
			<Contact showModal={showModal} closeModal={closeModal} />
		</Grid>
	);
}

export default Footer;
