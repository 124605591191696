import { useContext } from "react";
import { Box } from "@mui/material";
import SKEmail from "./securekey/Email";
import SKPassword from "./securekey/Password";
import widgetLogo from "../img/ontario-logo-desktop-2.png";
import SuccessScreen from "./common/SuccessScreen";
import Otp from "./common/Otp";
import { StateContext } from "../App";

const classes = {
	contentBox: {
		display: "flex",
		justifyContent: "center",
		minHeight: "calc(100vh - 15.65rem)",
		maxWidth: "90%",
	},
	inputContainer: {
		paddingLeft: 2,
		paddingRight: 2,
		paddingTop: 3,
		paddingBottom: 3,
	},
	logoImage: {
		maxWidth: "200px",
		maxHeight: "40px",
	},
	widgetContainer: {
		width: "400px",
		minWidth: "300px",
		border: "1px solid #ddd",
		marginTop: "30px",
		marginBottom: "30px",
		height: "fit-content",
	},
	widgetLogo: {
		paddingTop: "30px",
		paddingBottom: "30px",
		display: "flex",
		justifyContent: "center",
		borderBottom: "1px solid #ddd",
	},
	widgetOtp: {
		paddingTop: "30px",
		paddingBottom: "15px",
		display: "flex",
		justifyContent: "center",
		borderBottom: "none",
	},
};

function Mainpage(props) {
	const { state } = useContext(StateContext);

	return (
		<Box sx={classes.contentBox}>
			<Box sx={classes.widgetContainer}>
				<Box
					sx={
						state.screen == "OPS"
							? { ...classes.widgetOtp }
							: { ...classes.widgetLogo }
					}
				>
					<img alt="Ontario Logo" src={widgetLogo} style={classes.logoImage} />
				</Box>
				<Box sx={classes.inputContainer}>
					{state.screen == "EPS" && <SKEmail />}
					{state.screen == "PPS" && <SKPassword />}
					{state.screen == "OPS" && <Otp />}
					{state.screen == "SS" && <SuccessScreen />}
				</Box>
			</Box>
		</Box>
	);
}

export default Mainpage;
