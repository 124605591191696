import { useState, createContext, useEffect, useRef } from "react";

export const Context = createContext();

const Provider = ({ children }) => {
	const [locale, setLocale] = useState("en");
	const [secondaryEmail, setSecondaryEmail] = useState("");
	const initRender = useRef(true);

	useEffect(() => {
		if (sessionStorage.getItem("locale") && initRender.current) {
			return setLocale(sessionStorage.getItem("locale"));
		}
		sessionStorage.setItem("locale", locale);
	}, [locale]);

	useEffect(() => {
		initRender.current = false;
	}, []);

	return (
		<Context.Provider
			value={{
				locale,
				setLocale,
				secondaryEmail,
				setSecondaryEmail,
			}}
		>
			{children}
		</Context.Provider>
	);
};

export default Provider;
