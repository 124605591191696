import { useContext, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import {
	Grid,
	Box,
	TextField,
	Button,
	Link,
	Collapse,
	CircularProgress,
	Typography,
	Checkbox,
} from "@mui/material";
import Error from "./Error";
import Warning from "./Warning";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import MailIcon from "@mui/icons-material/Mail";
import { StateContext } from "../../App";
import Terms from "./Terms";
import { useOktaAuth } from "@okta/okta-react";
import Api from "../../services/Api";
import { Context } from "../../context/Context";
import text from "../../data/text";

const classes = {
	arrowIcon: {
		fontSize: "17px",
		marginRight: 0.5,
	},
	backButton: {
		marginTop: 1,
		width: "fit-content",
		padding: "4px",
	},
	buttonProgress: {
		color: "#fff",
	},
	checkbox: {
		padding: "0px",
		height: "fit-content",
		margin: "0",
	},
	checkboxWrapper: {
		width: "fit-content",
	},
	collapse: {
		marginBottom: 1.5,
	},
	emailStrong: {
		wordBreak: "break-word",
	},

	header: {
		fontSize: "18px",
		lineHeight: "1.56",
		fontWeight: "700",
		spacing: "0.03rem",
		color: "systemBlack.main",
		margin: "auto",
		textAlign: "center",
	},
	helperText: {
		lineHeight: "1.56",
		fontWeight: "400",
		fontSize: "1rem",
		spacing: "0.03rem",
		color: "greyscale70.main",
		marginTop: 1,
		marginBottom: 2,
	},
	imageBox: {
		marginBottom: "25px",
		width: "100%",
		position: "relative",
	},
	imageCircle: {
		width: "80px",
		height: "80px",
		borderRadius: "50%",
		backgroundColor: "#dff4ff",
	},
	label: {
		fontWeight: "700",
	},
	mailIcon: {
		fontSize: "50px",
		fontWeight: "100",
		color: "#0081cb",
	},
	modalLink: {
		color: "link.main",
		textDecoration: "none",
		"&:hover": {
			color: "linkHover.main",
			textDecoration: "underline",
		},
		"&:visited": {
			color: "linkVisited.main",
		},
	},
	outerBorder: {
		border: "2px solid #ddd",
		borderRadius: "50%",
		padding: "2px",
		"&:after": {
			position: "absolute",
			top: "49%",
			right: "0",
			content: "''",
			display: "block",
			backgroundColor: "#ddd",
			width: "35%",
			height: "1px",
		},
		"&:before": {
			position: "absolute",
			top: "49%",
			left: "0",
			content: "''",
			display: "block",
			backgroundColor: "#ddd",
			width: "35%",
			height: "1px",
		},
	},
	termsTitle: {
		fontSize: "0.85em",
	},
	termsBox: {
		marginBottom: 2,
	},
	termsWrapper: {
		width: "93%",
	},
	textInput: {
		width: "100%",
		height: "48px",
		marginTop: 1,
		marginBottom: 1,
	},
	updateButton: {
		width: "100%",
	},
};

function Otp(props) {
	const [warning, setWarning] = useState(false);
	const [otp, setOtp] = useState("");
	const [error, setError] = useState({ state: false, text: "" });
	const [loading, setLoading] = useState(false);
	const [modalOpen, setModalOpen] = useState(false);
	const [checked, setChecked] = useState(false);
	const { locale } = useContext(Context);
	const language = locale === "fr" ? "french" : "english";
	const { state, dispatch } = useContext(StateContext);
	const { authState } = useOktaAuth();
	const navigate = useNavigate();
	const warningSeconds = 15;

	useEffect(() => {
		let startTime = Math.trunc(new Date() / 1000);
		let x = setInterval(() => {
			if (Math.trunc(new Date() / 1000) - startTime > warningSeconds) {
				setWarning(true);
				clearInterval(x);
			}
		}, 1000);

		return () => window.clearInterval(x);
	}, []);

	const handleInput = (e) => {
		error.state && setError({ state: false, text: "" });
		setOtp(e.target.value.trim());
	};

	const handleSubmit = async (e) => {
		e.preventDefault();
		if (loading) return;
		if (!checked) {
			return;
		}
		if (!otp || otp.length < 1) {
			return setError({ state: true, text: "error9" });
		}
		setLoading(true);
		try {
			await Api.sendOtp(authState.accessToken.accessToken, {
				factorId: state.factorId,
				otp: otp,
			});
			dispatch({ type: "CHANGE_SCREEN", payload: "SS" });
		} catch (err) {
			setLoading(false);
			if (err.response && err.response.status == 401) {
				return navigate("/error");
			}
			if (err.response && err.response.data.errorCode == 69) {
				!warning && setWarning(true);
				dispatch({ type: "SET_FACTOR_ID", payload: err.response.data.factorId });
				return setError({
					state: true,
					text: "error11",
				});
			}
			if (err.response && err.response.data.errorCode == 4) {
				!warning && setWarning(true);
				return setError({
					state: true,
					text: "error10",
				});
			} else if (err.response && err.response.data.errorCode == 10) {
				return setError({
					state: true,
					text: "error3",
				});
			}
			setError({
				state: true,
				text: "error3",
			});
			return;
		}
	};

	return (
		<Grid direction="column" container>
			<Grid
				item
				container
				justifyContent="center"
				alignContent="center"
				sx={classes.imageBox}
			>
				<Box sx={classes.outerBorder}>
					<Grid
						item
						container
						justifyContent="center"
						alignContent="center"
						sx={classes.imageCircle}
					>
						<MailIcon sx={classes.mailIcon} />
					</Grid>
				</Box>
			</Grid>

			<Collapse in={error.state} sx={classes.collapse}>
				<Error error={error} />
			</Collapse>

			<Collapse in={warning} sx={classes.collapse}>
				<Warning setError={(err) => setError(err)} />
			</Collapse>

			<Typography htmlFor="otp-input" sx={classes.header} variant="h1">
				{text.content[language].otpForm.title}
			</Typography>
			<Typography id="otp-helper-text" sx={classes.helperText}>
				{text.content[language].otpForm.hint1}
				<strong style={classes.emailStrong}>
					{state.userEmail ? state.userEmail : "**email**"}
				</strong>
				.
				<br />
				{text.content[language].otpForm.hint2}
				<br />
			</Typography>

			<form onSubmit={handleSubmit}>
				<label htmlFor="otp-input" style={classes.label}>
					{text.content[language].otpForm.label}
				</label>
				<TextField
					id="otp-input"
					aria-describedby="otp-helper-text"
					variant="outlined"
					type="text"
					size="small"
					value={otp}
					sx={classes.textInput}
					onChange={handleInput}
				/>
				<Grid
					item
					container
					direction="row"
					justifyContent="space-evenly"
					alignItems="flex-start"
					sx={classes.termsBox}
				>
					<Grid
						item
						container
						justifyContent="flex-start"
						sx={classes.checkboxWrapper}
					>
						<Checkbox
							size="small"
							sx={classes.checkbox}
							inputProps={{
								"aria-label": "Terms of Service",
							}}
							checked={checked}
							onChange={() => {
								setChecked(!checked);
							}}
						/>
					</Grid>
					<Grid item sx={classes.termsWrapper}>
						<Typography sx={classes.termsTitle}>
							{text.content[language].otpForm.terms1}
							<Link href="#" sx={classes.modalLink} onClick={() => setModalOpen(true)}>
								{text.content[language].otpForm.terms2}
							</Link>
							{text.content[language].otpForm.terms3}
						</Typography>
					</Grid>
				</Grid>
				<Button
					variant="contained"
					color="secondary"
					sx={classes.updateButton}
					onClick={handleSubmit}
					disabled={!checked}
				>
					{!loading ? (
						text.content[language].otpForm.button
					) : (
						<CircularProgress size={24} sx={classes.buttonProgress} />
					)}
				</Button>
			</form>
			<Button
				onClick={() =>
					dispatch({
						type: "CHANGE_SCREEN",
						payload: "EPS",
					})
				}
				sx={classes.backButton}
			>
				<ArrowBackIcon sx={classes.arrowIcon} />{" "}
				{text.content[language].otpForm.returnButton}
			</Button>
			<Terms showModal={modalOpen} closeModal={(e) => setModalOpen(e)} />
		</Grid>
	);
}

export default Otp;
