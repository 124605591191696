import { Grid, Link, CircularProgress, Collapse, Alert } from "@mui/material";
import HelpIcon from "@mui/icons-material/Help";
import { StateContext } from "../../App";
import { useContext, useEffect, useState } from "react";
import Api from "../../services/Api";
import { useOktaAuth } from "@okta/okta-react";
import text from "../../data/text";
import { Context } from "../../context/Context";

const classes = {
	alert: {
		backgroundColor: "green.main",
	},
	checkIcon: {
		color: "green.main",
		padding: "0",
		margin: "0",
	},
	iconBox: {
		backgroundColor: "warning.main",
		minHeight: "100%",
		width: "100%",
		borderTopLeftRadius: "4px",
		borderBottomLeftRadius: "4px",
		paddingTop: 1,
		paddingBottom: 1,
	},
	resendCode: {
		marginLeft: 0.5,
		fontSize: "15px",
		paddingTop: "4px",
		paddingBottom: "3px",
		color: "link.main",
		textDecoration: "none",
		"&:hover": {
			color: "linkHover.main",
			textDecoration: "underline",
		},
		"&:visited": {
			color: "linkVisited.main",
		},
	},
	resendIcon: {
		width: "80px",
		paddingTop: "3px",
		paddingBottom: "3px",
	},
	success: {
		position: "fixed",
		top: "40px",
		width: "300px",
		marginLeft: "-135px",
		left: "50%",
		zIndex: "1300",
	},
	textBox: {
		border: "1px solid lightgray",
		borderLeft: "none",
		borderTopRightRadius: "4px",
		borderBottomRightRadius: "4px",
		paddingLeft: 1.5,
		fontSize: "15px",
	},
	warning: {
		marginBottom: 1,
		height: "30px",
	},
	warningIcon: {
		color: "#fff",
	},
};

function Warning(props) {
	const [resendOtpStatus, setResendOtpStatus] = useState("link"); //link || loading || success
	const { state } = useContext(StateContext);
	const { locale } = useContext(Context);
	const language = locale === "fr" ? "french" : "english";
	const { authState } = useOktaAuth();

	useEffect(() => {
		if (resendOtpStatus == "success") {
			setTimeout(() => {
				setResendOtpStatus("link");
			}, 6000);
		}
	}, [resendOtpStatus]);

	const resendOtp = async (e) => {
		e.preventDefault();
		setResendOtpStatus("loading");
		try {
			await Api.resendOtp(authState.accessToken.accessToken, {
				factorId: state.factorId,
			});
			setResendOtpStatus("success");
			return props.setError({
				state: false,
				text: "",
			});
		} catch (err) {
			setResendOtpStatus("link");
			if (err.response && err.response.data.errorCode == 9) {
				return props.setError({
					state: true,
					text: "error3",
				});
			}
		}
	};

	return (
		<Grid direction="row" container sx={classes.warning}>
			<Grid
				item
				xs={2}
				sm={2}
				md={2}
				lg={2}
				xl={2}
				sx={classes.iconBox}
				container
				justifyContent="center"
				alignItems="center"
			>
				<HelpIcon sx={classes.warningIcon} />
			</Grid>
			<Grid
				item
				xs={10}
				sm={10}
				md={10}
				lg={10}
				xl={10}
				container
				justifyContent="flex-start"
				alignItems="center"
				sx={classes.textBox}
			>
				{text.content[language].errors.warning1}
				{resendOtpStatus == "link" && (
					<Link component="button" sx={classes.resendCode} onClick={resendOtp}>
						{text.content[language].errors.warning2}
					</Link>
				)}
				{(resendOtpStatus == "loading" || resendOtpStatus == "success") && (
					<Grid
						container
						justifyContent="center"
						alignItems="center"
						direction="row"
						sx={classes.resendIcon}
					>
						<CircularProgress size={16} />
					</Grid>
				)}
			</Grid>
			<Collapse in={resendOtpStatus == "success"} sx={classes.success}>
				<Alert severity="success" variant="filled" sx={classes.alert}>
					{text.content[language].errors.success1}
				</Alert>
			</Collapse>
		</Grid>
	);
}

export default Warning;
