export const cookieEraser = () => {
	const host = window.location.hostname.split(".")[0];
	let cookies = document.cookie.split(";");
	for (let cookie of cookies) {
		const cookieName = cookie.split("=")[0];
		if (
			(host == "profile" && cookieName.trim() == "cid") ||
			cookieName.split("_")[0].trim() == host
		) {
			document.cookie = `${cookieName}=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/; domain=.keydatalab.ca`;
		}
		if (cookieName.trim() === "pa_path") {
			document.cookie = `${cookieName}=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/; domain=.keydatalab.ca`;
		}
		if (cookieName.trim() === "pa_query") {
			document.cookie = `${cookieName}=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/; domain=.keydatalab.ca`;
		}
	}

	return;
};
