export const cookieGetter = () => {
	const host = window.location.hostname.split(".")[0];
	let cid = null;
	let pa_path = null;
	let pa_query = null;
	let cookies = document.cookie.split(";");
	for (let cookie of cookies) {
		const cookieName = cookie.split("=")[0];
		if (
			(host == "profile" && cookieName.trim() == "cid") ||
			cookieName.split("_")[0].trim() == host
		) {
			cid = cookie.split("=")[1];
			//document.cookie = `${cookieName}=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/; domain=.signin.ontario.ca`;
		}
		if (cookieName.trim() === "pa_path") {
			pa_path = cookie.split("=")[1];
			//document.cookie = `${cookieName}=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/; domain=.signin.ontario.ca`;
		}
		if (cookieName.trim() === "pa_query") {
			/* splitting by 'pa_query=' because cookie value has '=' in it */
			pa_query = cookie.split("pa_query=")[1];
			//document.cookie = `${cookieName}=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/; domain=.signin.ontario.ca`;
		}
	}

	return { cid, pa_path, pa_query };
};
