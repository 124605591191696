import {
	CHANGE_SCREEN,
	SET_FACTOR_ID,
	SET_USER_EMAIL,
	SET_SESSION_TOKEN,
} from "../actions/actions";

export const initState = {
	factorId: sessionStorage.getItem("factorId") || "",
	userEmail: sessionStorage.getItem("userEmail") || "",
	screen: sessionStorage.getItem("s") || "",
	sessionToken: sessionStorage.getItem("sessionToken") || null,
};

export const reducer = (state = initState, action) => {
	const { type, payload } = action;
	switch (type) {
		case CHANGE_SCREEN:
			if (payload == "SS") {
				sessionStorage.removeItem("factorId");
				sessionStorage.removeItem("userEmail");
			}
			sessionStorage.setItem("s", payload);
			return { ...state, screen: payload };

		case SET_USER_EMAIL:
			sessionStorage.setItem("userEmail", payload);
			return { ...state, userEmail: payload };

		case SET_FACTOR_ID:
			sessionStorage.setItem("factorId", payload);
			return { ...state, factorId: payload };

		case SET_SESSION_TOKEN:
			sessionStorage.setItem("sessionToken", payload);
			return { ...state, sessionToken: payload };

		default:
			return state;
	}
};
