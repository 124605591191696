import { useCallback } from "react";
import { Route, useNavigate, Routes } from "react-router-dom";
import { Security } from "@okta/okta-react";
import { OktaAuth, toRelativeUrl } from "@okta/okta-auth-js";
import Home from "./components/Home";
import Login from "./components/Login";
import { oktaAuthConfig } from "./config";
import ErrorPage from "./components/common/ErrorPage";
import Health from "./components/Health";
import { RequiredAuth } from "./components/SecureRoute";

const oktaAuth = new OktaAuth(oktaAuthConfig);

const AppWithRouterAccess = () => {
	const navigate = useNavigate();
	const customAuthHandler = () => {
		navigate("/login");
	};

	const restoreOriginalUri = useCallback(async (_oktaAuth, originalUri) => {
		navigate(toRelativeUrl(originalUri || "/", window.location.origin), {
			replace: true,
		});
	}, []);

	return (
		<Security
			oktaAuth={oktaAuth}
			onAuthRequired={customAuthHandler}
			restoreOriginalUri={restoreOriginalUri}
		>
			<Routes>
				<Route path={"/login"} exact={true} element={<Login />} />
				<Route path={"/profile"} exact={true} element={<RequiredAuth />}>
					<Route path="" exact={true} element={<Home />} />
				</Route>
				<Route path={"/error"} exact={true} element={<ErrorPage />} />
				<Route path="/healthcheck" exact={true} element={<Health />} />
				<Route path="*" element={<Login />} />
			</Routes>
		</Security>
	);
};
export default AppWithRouterAccess;
