import { useContext } from "react";
import {
	Dialog,
	DialogContent,
	DialogTitle,
	IconButton,
	Typography,
	Divider,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import { Context } from "../../context/Context";
import text from "../../data/text";

const classes = {
	boldText: {
		fontWeight: "600",
	},
	contactsWrapper: {
		display: "flex",
		flexDirection: "row",
		fontWeight: "600",
	},
	closeButton: {
		position: "absolute",
		top: "24px",
		right: "24px",
	},
	dialogContent: {
		padding: "4%",
	},
	dialogTitle: {
		position: "relative",
		padding: "4%",
	},
	divider: {
		height: "4px",
		width: "92%",
		margin: "auto",
	},
	header: {
		fontSize: "1.75rem",
		letterSpacing: ".02rem",
		lineHeight: "1.39",
		marginBottom: "1rem",
		fontWeight: "700",
	},
	link: {
		marginLeft: "5px",
		fontFamily: '"Raleway", "Open Sans", "Arial", sans-serif',
		color: "link.main",
		"&:visited": {
			color: "linkVisited.main",
		},
		"&:hover": {
			color: "linkHover.main",
			textDecoration: "underline",
		},
	},
	schedule: {
		marginTop: "30px",
		marginBottom: "30px",
		whiteSpace: "pre-line",
	},
	subHeader: {
		fontSize: "1.5rem",
		letterSpacing: ".03rem",
		lineHeight: "1.5",
		fontWeight: "700",
	},
	title: {
		marginTop: "30px",
		marginBottom: "20px",
	},
};

function Contact(props) {
	const { locale } = useContext(Context);
	const language = locale === "fr" ? "french" : "english";

	return (
		<Dialog
			open={props.showModal}
			onClose={() => props.closeModal()}
			fullWidth={true}
			maxWidth={"md"}
			scroll="body"
		>
			<DialogTitle sx={classes.dialogTitle}>
				<img
					src="https://op3static.oktacdn.com/fs/bco/1/fs0ikh3u6aus6gh0j1d6"
					alt="Ontario"
				/>
				<Typography variant="h3" sx={classes.header}>
					{text.contact[language].header}
				</Typography>
				<Typography variant="h4" sx={classes.subHeader}>
					{text.contact[language].subHeader}
				</Typography>
				<IconButton
					size="small"
					sx={classes.closeButton}
					onClick={() => props.closeModal()}
					aria-label="Close Contact information"
				>
					<CloseIcon fontSize="small" />
				</IconButton>
			</DialogTitle>
			<Divider sx={classes.divider} variant="middle" />
			<DialogContent sx={classes.dialogContent}>
				<Typography>{text.contact[language].paragraph}</Typography>
			</DialogContent>
		</Dialog>
	);
}

export default Contact;
