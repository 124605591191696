import { useState, useContext } from "react";
import { useNavigate } from "react-router-dom";
import {
	Grid,
	Typography,
	Button,
	Collapse,
	CircularProgress,
	Box,
} from "@mui/material";
import { StateContext } from "../../App";
// import { useOktaAuth } from "@okta/okta-react";
import Error from "./Error";
// import Api from "../../services/Api";
import text from "../../data/text";
import { Context } from "../../context/Context";
import checkImage from "../../img/check.png";
import parse from "html-react-parser";
import { cookieGetter } from "../../helpers/cookieGetter";
import { cookieEraser } from "../../helpers/cookieEraser";

const classes = {
	button: {
		width: "100%",
	},
	buttonProgress: {
		color: "#fff",
	},
	checkIcon: {
		width: "100%",
		height: "100%",
	},
	collapse: {
		marginBottom: 1.5,
		width: "100%",
	},
	greenText: {
		textAlign: "center",
		marginBottom: "20px",
		color: "green.main",
		fontSize: "20px",
		fontWeight: "bold",
	},
	iconBox: {
		marginBottom: "10px",
		width: "40px",
		height: "40px",
	},
	mainText: {
		marginBottom: "30px",
		padding: "2px",
	},
};

function SuccessScreen(props) {
	const [error, setError] = useState({ state: false, text: "" });
	const [loading, setLoading] = useState(false);
	const { cid, pa_path, pa_query } = cookieGetter();
	const { state } = useContext(StateContext);
	const { locale } = useContext(Context);
	const language = locale === "fr" ? "french" : "english";
	// const { authState } = useOktaAuth();
	const navigate = useNavigate();

	const submit = async () => {
		setLoading(true);
		/* checking if we have cookies for the environment  */
		try {
			cookieEraser();
			if (!cid || cid == "null") {
				let redirectUrlWithParams = process.env.REACT_APP_BASE_URL;
				if (state.sessionToken) {
					redirectUrlWithParams =
						process.env.REACT_APP_BASE_URL +
						"/login/sessionCookieRedirect?token=" +
						state.sessionToken +
						"&redirectUrl=" +
						process.env.REACT_APP_BASE_URL;
				}
				sessionStorage.clear();
				// cookieEraser();
				return (window.location.href = redirectUrlWithParams);
			}
			/* 
			   pa_query is expected to be present on OIDC and SAML GET binding
			   pa_query is expected to be NULL on SAML POST binding
			*/
			/*
				sessionToken will only be present during password flow:
				Linking existing MyOntario account with new SIP requires user to submit password
			*/
			if (pa_path && pa_path != "null") {
				let redirectUrlWithParams =
					process.env.REACT_APP_BASE_URL +
					pa_path +
					(pa_query && pa_query != "null" ? "?" + pa_query : "") +
					(state.sessionToken
						? pa_query && pa_query != "null"
							? "&sessionToken=" + state.sessionToken
							: "?sessionToken=" + state.sessionToken
						: "");
				sessionStorage.clear();
				// cookieEraser();
				return (window.location.href = redirectUrlWithParams);
			}

			//redirect to dash
			// cookieEraser();
			return (window.location.href = process.env.REACT_APP_BASE_URL);
			/* SAML APP */
			// let resp = await Api.getTargetUrl(authState.accessToken.accessToken, {
			// 	clientId: cid,
			// });
			// if (resp.error) {
			// 	setLoading(false);
			// 	return setError({
			// 		state: true,
			// 		text: "error3",
			// 	});
			// } else {
			// 	/* Redirect URL + session token */
			// 	let redirectUrlWithParams =
			// 		resp.redirectUrl +
			// 		(state.sessionToken ? "?sessionToken=" + state.sessionToken : "");
			// 	sessionStorage.clear();
			// 	return (window.location.href = redirectUrlWithParams);
			// }
		} catch (err) {
			setLoading(false);
			if (err.response && err.response.status == 401) {
				return navigate("/error");
			}
			if (err.response && err.response.data.errorCode == 8) {
				return setError({
					state: true,
					text: "error3",
				});
			}
		}
	};

	return (
		<Grid container alignItems="center" direction="column">
			<Collapse in={error.state} sx={classes.collapse}>
				<Error error={error} />
			</Collapse>
			<Box sx={classes.iconBox}>
				<img
					src={checkImage}
					style={classes.checkIcon}
					alt={locale === "fr" ? "Activation réussie" : "Successful activation"}
				/>
			</Box>
			<Typography sx={classes.greenText} variant="h1">
				{text.content[language].successScreen.title}
			</Typography>

			<Typography sx={classes.mainText}>
				{state.factorId
					? parse(text.content[language].successScreen.hint1_no_pass)
					: parse(text.content[language].successScreen.hint1)}
				<>
					<br />
					<br />
				</>
				{state.factorId
					? parse(text.content[language].successScreen.hint2_no_pass)
					: parse(text.content[language].successScreen.hint2)}
			</Typography>
			<Button
				variant="contained"
				color="secondary"
				sx={classes.button}
				onClick={submit}
			>
				{!loading ? (
					text.content[language].successScreen.button
				) : (
					<CircularProgress size={24} sx={classes.buttonProgress} />
				)}
			</Button>
		</Grid>
	);
}

export default SuccessScreen;
