import React, { useEffect, useContext } from "react";
import { Grid, Typography, Box, Link } from "@mui/material";
import Navbar from "./Navbar";
import Footer from "./Footer";
import WarningIcon from "@mui/icons-material/Warning";
import { useOktaAuth } from "@okta/okta-react";
import widgetLogo from "../../img/ontario-logo-desktop-2.png";
import { Context } from "../../context/Context";
import text from "../../data/text";

const classes = {
	contentBox: {
		display: "flex",
		justifyContent: "center",
		minHeight: "calc(100vh - 15.65rem)",
	},
	errorText: {
		fontSize: "16px",
		textAlign: "center",
		marginTop: 2,
	},
	errorTitle: {
		fontSize: "26px",
		textAlign: "center",
	},
	home: {
		width: "100%",
		minHeight: "100vh",
		display: "flex",
		justifyContent: "center",
		alignItems: "center",
		flexDirection: "column",
		position: "relative",
	},
	inputContainer: {
		paddingLeft: 2,
		paddingRight: 2,
		paddingTop: 3,
		paddingBottom: 3,
	},
	link: {
		border: "1px solid",
		borderColor: "secondary.main",
		borderRadius: "4px",
		padding: "10px",
		transition: ".2s",
		cursor: "pointer",
		lineHeight: "20px",
		marginTop: "20px",
		textDecoration: "none",
		"&:hover": {
			backgroundColor: "secondary.main",
			color: "white",
		},
	},
	logoImage: {
		maxWidth: "200px",
		maxHeight: "40px",
	},
	warningIcon: {
		fontSize: "108px",
		color: "red",
	},
	widgetContainer: {
		width: "400px",
		minWidth: "300px",
		border: "1px solid #ddd",
		marginTop: "30px",
		marginBottom: "30px",
		height: "fit-content",
	},
	widgetLogo: {
		paddingTop: "30px",
		paddingBottom: "30px",
		display: "flex",
		justifyContent: "center",
		borderBottom: "1px solid #ddd",
	},
	widgetOtp: {
		borderBottom: "none",
		paddingBottom: "15px",
	},
};

function ErrorPage(props) {
	const { oktaAuth } = useOktaAuth();
	const { locale } = useContext(Context);

	const language = locale === "fr" ? "french" : "english";

	useEffect(() => {
		async function clearTokens() {
			await oktaAuth.session.close();
			await oktaAuth.tokenManager.clear();
		}
		clearTokens();
	}, []);

	return (
		<Box sx={classes.home}>
			<Navbar />
			<Box sx={classes.contentBox}>
				<Box sx={classes.widgetContainer}>
					<Box sx={classes.widgetLogo}>
						<img alt="Ontario Logo" src={widgetLogo} style={classes.logoImage} />
					</Box>
					<Box sx={classes.inputContainer}>
						<Grid
							container
							justifyContent="flex-start"
							direction="column"
							alignItems="center"
						>
							<WarningIcon sx={classes.warningIcon} />
							<Typography sx={classes.errorTitle}>
								{text.content[language].errorPage.title}
							</Typography>
							<Typography sx={classes.errorText}>
								{text.content[language].errorPage.text}
							</Typography>
							<Link
								sx={classes.link}
								color="secondary"
								href={process.env.REACT_APP_BASE_URL}
							>
								{text.content[language].errorPage.link}
							</Link>
						</Grid>
					</Box>
				</Box>
			</Box>
			<Footer />
		</Box>
	);
}

export default ErrorPage;
