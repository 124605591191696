import { useReducer, createContext } from "react";
import { BrowserRouter as Router } from "react-router-dom";
import AppWithRouterAccess from "./AppWithRouterAccess";
import { ThemeProvider } from "@mui/material";
import { theme } from "./themes/theme";
import { reducer, initState } from "./store/reducers/reducers";

export const StateContext = createContext();

const App = () => {
	const [state, dispatch] = useReducer(reducer, initState);
	return (
		<StateContext.Provider value={{ state, dispatch }}>
			<ThemeProvider theme={theme}>
				<Router>
					<AppWithRouterAccess />
				</Router>
			</ThemeProvider>
		</StateContext.Provider>
	);
};

export default App;
