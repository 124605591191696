import React, { useEffect, useState, useContext } from "react";
import {
	Button,
	Dialog,
	DialogActions,
	DialogContent,
	DialogTitle,
	Slide,
	Typography,
	Grid,
} from "@mui/material";
import { useOktaAuth } from "@okta/okta-react";
import { Context } from "../context/Context";
import text from "../data/text";

const classes = {
	button: {
		margin: "10px auto 10px",
	},
	countdown: {
		fontSize: "28px",
	},
	dialogTitle: {
		fontSize: "24px",
	},
	textCenter: {
		textAlign: "center",
	},
};

const Transition = React.forwardRef(function Transition(props, ref) {
	return <Slide direction="up" ref={ref} {...props} />;
});

function SignOutModal(props) {
	const [count, setCount] = useState(300); //seconds
	const [isExpired, setIsExpired] = useState(false);
	const { locale } = useContext(Context);
	const language = locale == "fr" ? "french" : "english";
	const { oktaAuth } = useOktaAuth();
	let timer;

	useEffect(() => {
		timer = window.setInterval(() => {
			setCount((prev) => {
				if (prev < 2) {
					window.clearInterval(timer);
					return 0;
				}
				return prev - 1;
			});
		}, 1000);
		return () => window.clearInterval(timer);
	}, []);

	useEffect(() => {
		destroySession();
	}, [count]);

	const destroySession = async () => {
		if (count < 1) {
			setIsExpired(true);
			sessionStorage.clear();
			await oktaAuth.session.close();
			await oktaAuth.tokenManager.clear();
		}
	};

	const redirect = () => {
		return (window.location.href = process.env.REACT_APP_BASE_URL);
	};

	return (
		<Dialog
			open={props.showModal}
			keepMounted
			onClose={isExpired ? redirect : props.keepMe}
			TransitionComponent={Transition}
			fullWidth={true}
			maxWidth={"sm"}
		>
			<DialogTitle>
				<Typography sx={{ ...classes.textCenter, ...classes.dialogTitle }}>
					{text.content[language].signOutMessage.title}
				</Typography>
			</DialogTitle>
			<DialogContent>
				<Grid justifyContent="center" direction="column" container>
					{isExpired ? (
						<Typography sx={classes.textCenter}>
							{text.content[language].signOutMessage.message2}
						</Typography>
					) : (
						<Typography sx={classes.textCenter}>
							{text.content[language].signOutMessage.message1}
							<br />
							<b style={classes.countdown}>{Math.trunc(count / 60)}:</b>
							<b style={classes.countdown}>
								{Math.trunc(count / 60) > 0
									? count - Math.trunc(count / 60) * 60 > 9
										? count - Math.trunc(count / 60) * 60
										: "0" + (count - Math.trunc(count / 60) * 60)
									: count > 9
									? count
									: "0" + count}
							</b>
						</Typography>
					)}
				</Grid>
			</DialogContent>
			<DialogActions>
				<Button
					variant="contained"
					color="secondary"
					onClick={isExpired ? redirect : props.keepMe}
					sx={classes.button}
				>
					{isExpired
						? text.content[language].signOutMessage.button2
						: text.content[language].signOutMessage.button1}
				</Button>
			</DialogActions>
		</Dialog>
	);
}

export default SignOutModal;
