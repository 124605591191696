import { useContext, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import {
	Grid,
	TextField,
	Button,
	Collapse,
	CircularProgress,
	Typography,
} from "@mui/material";
import Error from "../common/Error";
import Api from "../../services/Api";
import { useOktaAuth } from "@okta/okta-react";
import { StateContext } from "../../App";
import { Context } from "../../context/Context";
import text from "../../data/text";
import parse from "html-react-parser";

const classes = {
	buttonProgress: {
		color: "#fff",
	},
	collapse: {
		marginBottom: 1.5,
	},
	form: {
		marginTop: 1,
		position: "relative",
	},
	header: {
		fontSize: "18px",
		lineHeight: "1.56",
		fontWeight: "700",
		spacing: "0.03rem",
		color: "systemBlack.main",
		margin: "0px auto 10px",
		width: "fit-content",
		textAlign: "center",
	},
	hint: {
		marginBottom: 1,
	},
	label: {
		fontWeight: "700",
	},
	textInput: {
		width: "100%",
		height: "48px",
		marginTop: 1,
		marginBottom: 1,
	},
	updateButton: {
		width: "100%",
	},
};

function SKEmail(props) {
	const [email, setEmail] = useState("");
	const [error, setError] = useState({ state: false, text: "" });
	const [loading, setLoading] = useState(false);
	const { locale, secondaryEmail } = useContext(Context);
	const language = locale == "fr" ? "french" : "english";
	const { dispatch } = useContext(StateContext);
	const { authState } = useOktaAuth();
	const navigate = useNavigate();

	useEffect(() => {
		setEmail(secondaryEmail);
	}, []);

	const handleInput = (e) => {
		error.state && setError({ state: false, text: "" });
		setEmail(e.target.value);
	};

	const handleSubmit = async (e) => {
		e.preventDefault();
		if (loading) return;
		if (!email || email.length < 1) {
			return setError({
				state: true,
				text: "error1",
			});
		}
		//basic email validation - throw error if fails
		let regex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
		if (regex.test(email)) {
			setLoading(true);
			try {
				let response = await Api.sendEmail(authState.accessToken.accessToken, {
					email,
				});
				dispatch({ type: "SET_USER_EMAIL", payload: email });
				if (response.response == "password") {
					return dispatch({
						type: "CHANGE_SCREEN",
						payload: "PPS",
					});
				} else {
					dispatch({ type: "SET_FACTOR_ID", payload: response.factorId });
					return dispatch({
						type: "CHANGE_SCREEN",
						payload: "OPS",
					});
				}
			} catch (err) {
				setLoading(false);
				if (err.response && err.response.status == 401) {
					return navigate("/error");
				}
				if (err.response && err.response.data.errorCode == 6) {
					return setError({
						state: true,
						text: "error2",
					});
				}
				if (err.response && err.response.data.errorCode == 5) {
					return setError({
						state: true,
						text: "error4",
					});
				}
				return setError({
					state: true,
					text: "error3",
				});
			}
		} else {
			return setError({
				state: true,
				text: "error2",
			});
		}
	};

	return (
		<Grid direction="column" container>
			<Collapse in={error.state} sx={classes.collapse}>
				<Error error={error} />
			</Collapse>
			<Typography sx={classes.header} variant="h1">
				{text.content[language].emailForm.title}
			</Typography>
			<Typography sx={classes.hint}>
				{parse(text.content[language].emailForm.hint1)}
			</Typography>
			<Typography sx={classes.hint}>
				{text.content[language].emailForm.hint2}
			</Typography>
			<form onSubmit={handleSubmit} style={classes.form}>
				<label htmlFor="email-input" style={classes.label}>
					{text.content[language].emailForm.label}
				</label>
				<TextField
					id="email-input"
					name="email"
					//aria-describedby="email-helper-text"
					variant="outlined"
					type="email"
					size="small"
					value={email}
					sx={classes.textInput}
					onChange={handleInput}
				/>
				<Button
					variant="contained"
					color="secondary"
					sx={classes.updateButton}
					onClick={handleSubmit}
				>
					{!loading ? (
						text.content[language].emailForm.button
					) : (
						<CircularProgress size={24} sx={classes.buttonProgress} />
					)}
				</Button>
			</form>
		</Grid>
	);
}

export default SKEmail;
