import axios from "axios";
const env_context = "/" + process.env.REACT_APP_CONTEXT_VARIABLE;
const client = axios.create({
	baseURL: process.env.REACT_APP_BACKEND_URL,
	json: true,
});

const Api = {
	async execute(accessToken, method, resource, data) {
		return client({
			method,
			url: resource,
			data,
			headers: {
				Authorization: `Bearer ${accessToken}`,
				"X-IBM-Client-Id": process.env.REACT_APP_X_IBM,
			},
		}).then((res) => {
			return res.data;
		});
	},
	sendEmail(accessToken, data) {
		return this.execute(accessToken, "post", `${env_context}/user/check`, data);
	},
	sendPassword(accessToken, data) {
		return this.execute(accessToken, "post", `${env_context}/user/link`, data);
	},
	sendOtp(accessToken, data) {
		return this.execute(
			accessToken,
			"post",
			`${env_context}/user/verifyOTP`,
			data
		);
	},
	getTargetUrl(accessToken, data) {
		return this.execute(
			accessToken,
			"post",
			`${env_context}/user/targeturl`,
			data
		);
	},
	resendOtp(accessToken, data) {
		return this.execute(
			accessToken,
			"post",
			`${env_context}/user/resendotp`,
			data
		);
	},
	assignGroup(accessToken) {
		return this.execute(accessToken, "post", `${env_context}/user/groupassign`);
	},
};

export default Api;
