import { Grid } from "@mui/material";
import ErrorIcon from "@mui/icons-material/Error";
import { useContext } from "react";
import { Context } from "../../context/Context";
import text from "../../data/text";

const classes = {
	errorIcon: {
		color: "#fff",
	},
	iconBox: {
		backgroundColor: "#CD0000",
		minHeight: "100%",
		width: "100%",
		borderTopLeftRadius: "4px",
		borderBottomLeftRadius: "4px",
		paddingTop: 1,
		paddingBottom: 1,
	},
	textBox: {
		border: "1px solid lightgray",
		borderLeft: "none",
		borderTopRightRadius: "4px",
		borderBottomRightRadius: "4px",
		paddingLeft: 1.5,
		paddingRight: 1,
		fontSize: "15px",
	},
};

function Error(props) {
	const { locale } = useContext(Context);
	return (
		<Grid direction="row" container>
			<Grid
				item
				xs={2}
				sm={2}
				md={2}
				lg={2}
				xl={2}
				sx={classes.iconBox}
				container
				justifyContent="center"
				alignItems="center"
			>
				<ErrorIcon sx={classes.errorIcon} />
			</Grid>
			<Grid
				item
				container
				xs={10}
				sm={10}
				md={10}
				lg={10}
				xl={10}
				justifyContent="flex-start"
				alignItems="center"
				sx={classes.textBox}
			>
				{locale === "fr"
					? text.content.french.errors[props.error.text]
					: text.content.english.errors[props.error.text]}
			</Grid>
		</Grid>
	);
}

export default Error;
