const host = window.location.hostname.split(".")[0];

const text = {
	header: {
		english: {
			language: "English",
			mobileLanguage: "EN",
		},
		french: {
			language: "Français",
			mobileLanguage: "FR",
		},
	},
	content: {
		english: {
			errors: {
				error1: "Email can not be empty!",
				error2: "Email format is incorrect!",
				error3: "Error occurred. Please try again.",
				error4: "An account with this email exists. Please use a different email.",
				error5: "Your account has been locked!",
				error6: "Password is incorrect!",
				error7: "This account is already linked to another banking institution!",
				error8: "Password can not be empty!",
				error9: "Code can not be empty!",
				error10: "Verification code does not match or has expired.",
				error11: 'Too many attempts! Click "Resend Code" to use a new code',
				warning1: "Haven't received the code?",
				warning2: "Resend code",
				success1: "New verification code was sent",
			},
			emailForm: {
				title: "Add your email address to continue",
				hint1:
					"We have noticed that you are using our Sign-In Partner <i>Interac</i>® sign-in service for the first time.",
				hint2:
					"To use this service, you will have to add your email address. You will only have to do this once.",
				label: "Email",
				button: "Continue",
			},
			otpForm: {
				title: "Verify with Email Authentication",
				hint1: "A verification code was sent to ",
				hint2: "Check your email and enter the code below.",
				hint3: "Note: This code is only valid for 5 minutes.",
				label: "Verification code",
				terms1: "I agree to the ",
				terms2: "Terms of Service",
				terms3: " for account validation",
				button: "Continue",
				returnButton: "Back",
			},
			passwordForm: {
				title: "Enter your password",
				hint1:
					"You already have an existing My Ontario Account with the email you just provided. Enter your password now to finish activating your account.",
				label: "Password",
				button: "Continue",
				returnButton: "Back",
				hint: "Forgot your password?",
			},
			passwordHelp: {
				title: "Follow these steps:",
				steps: [
					"Close your browser",
					"Open a new browser window and go back to the online government service you would like to access",
					'From the Sign in page, click on "Need help signing in", and then click the "Forgot password" link to reset your password',
					"Follow the steps to reset your password",
					"Go back to the online government service you would like to access. You can now sign-in using Sign-In Partner. Enter your sign-in partner login details. Then enter your email and your newly reset My Ontario Account password to link your two logins to the same account",
				],
			},
			successScreen: {
				title: "Your account has been activated",
				hint1:
					"In the future, you can sign in using our Sign-In Partner <i>Interac</i>® sign-in service.",
				hint2:
					"You can also use the email address you provided to sign in by clicking on the Forgot Password link and setting up a new password.",
				hint1_no_pass:
					"In the future, you can sign in using our Sign-In Partner <i>Interac</i>® sign-in service.",
				hint2_no_pass:
					"You can also use the email address you provided to sign in by clicking on the Forgot Password link and setting up a new password.",
				button: "Continue",
			},
			signOutMessage: {
				title: "We've noticed you were away",
				message1: "Are you still working on it? Your session will expire in",
				message2: "Your session has expired",
				button1: "Keep me signed in",
				button2: "Continue",
			},
			errorPage: {
				title: "Error has occurred",
				text: "This is likely because your session is invalid or has expired",
				link: "Go to Login page",
			},
		},
		french: {
			errors: {
				error1: "Une adresse courriel doit être indiquée!",
				error2: "Le format de l’adresse courriel est erroné!",
				error3: "Une erreur s’est produite. Essayez de nouveau.",
				error4:
					"Un compte est déjà lié à cette adresse courriel. Utilisez une autre adresse courriel.",
				error5: "Votre compte est verrouillé!",
				error6: "Le mot de passe est erroné!",
				error7: "Ce compte est déjà lié à une autre institution bancaire!",
				error8: "Un mot de passe doit être indiqué!",
				error9: "Un code doit être indiqué!",
				error10: "Le code de vérification est erroné ou a expiré.",
				error11: 'FR Too many attempts! Click "Resend Code" to use a new code',
				warning1: "Vous n’avez pas reçu de code?",
				warning2: "Envoyer de nouveau un code.",
				success1: "Un nouveau code de vérification a été envoyé",
			},
			emailForm: {
				title: "Entrez votre adresse courriel pour continuer",
				hint1:
					"Nous avons remarqué que vous utilisez le service de notre partenaire de connexion <i className='no-word-break'>Interac<span className='sup'>MD</span></i> pour la première fois.",
				hint2:
					"Pour utiliser ce service, vous devez indiquer votre adresse courriel. Vous n’aurez plus à le faire par la suite.",
				label: "Courriel",
				button: "Continuer",
			},
			otpForm: {
				title: "Vérification grâce à l’authentification par courriel",
				hint1: "Un code de vérification a été envoyé à l’adresse ",
				hint2: "Consultez votre boîte de réception et entrez le code ci-dessous.",
				hint3: "Remarque : Ce code n’est valide que pendant cinq minutes.",
				label: "Code de vérification",
				terms1: "J’accepte ",
				terms2: "les conditions d’utilisation",
				terms3: " pour la validation du compte.",
				button: "Continuer",
				returnButton: "Retour",
			},
			passwordForm: {
				title: "Entrez votre mot de passe",
				hint1:
					"Le courriel que vous avez fourni correspond à un compte Mon Ontario qui existe déjà. Entrez votre mot de passe maintenant pour terminer l’activation de votre compte.",
				label: "Mot de passe",
				button: "Continuer",
				returnButton: "Retour",
				hint: "Mot de passe oublié?",
			},
			passwordHelp: {
				title: "Veuillez suivre les étapes suivantes :",
				steps: [
					"Fermez votre navigateur",
					"Ouvrez votre navigateur, puis retournez au service gouvernemental en ligne auquel vous souhaitez accéder",
					"Depuis la page Ouvrir une session, cliquez sur «Aide pour ouvrir une session», puis sur le lien «Mot de passe oublié» en vue de réinitialiser votre mot de passe",
					"Suivez les étapes pour réinitialiser votre mot de passe",
					"Retournez au service gouvernemental en ligne auquel vous souhaitez accéder. Vous pouvez maintenant vous connecter en utilisant Partenaire de connexion. Entrez vos données de connexion de Partenaire de connexion. Ensuite, entrez votre adresse courriel et votre mot de passe de votre compte Mon Ontario récemment réinitialisé pour lier vos deux connexions au même compte",
				],
			},
			successScreen: {
				title: "Votre compte a été activé",
				hint1:
					"À l’avenir, vous pourrez vous connecter en utilisant le service de connexion de notre partenaire <i className='no-word-break'>Interac<span className='sup'>MD</span></i>.",
				hint2:
					"Vous pouvez également utiliser l’adresse courriel que vous avez fournie pour vous connecter en cliquant sur le lien <i>Mot de passe oublié</i> et en créant un nouveau mot de passe.",
				hint1_no_pass:
					"À l’avenir, vous pourrez vous connecter en utilisant le service de connexion de notre partenaire <i className='no-word-break'>Interac<span className='sup'>MD</span></i>.",
				hint2_no_pass:
					"Vous pouvez également utiliser l’adresse courriel que vous avez fournie pour vous connecter en cliquant sur le lien <i>Mot de passe oublié</i> et en créant un nouveau mot de passe.",
				button: "Continuer",
			},
			signOutMessage: {
				title: "Nous avons constaté que vous vous êtes absenté(e).",
				message1: "Travaillez-vous encore? Votre session expirera dans",
				message2: "Votre session a expiré.",
				button1: "Gardez ma session active",
				button2: "Continuer",
			},
			errorPage: {
				title: "Une erreur est survenue",
				text:
					"Cela est probablement dû au fait que votre session n'est pas valide ou a expiré",
				link: "Aller à la page de connexion",
			},
		},
	},
	footer: {
		english: {
			accessibility: {
				title: "Accessibility",
				url: "https://www.ontario.ca/page/accessibility",
			},
			privacy: {
				title: "Privacy",
				url: "https://www.ontario.ca/page/privacy-statement",
			},
			contact: "Contact us",
			help: "Help",
			copyright: {
				title: "© King’s Printer for Ontario, ",
				url: "https://www.ontario.ca/page/copyright-information",
			},
		},
		french: {
			accessibility: {
				title: "Accessibilité",
				url: "https://www.ontario.ca/fr/page/accessibilite",
			},
			privacy: {
				title: "Vie privée",
				url: "https://www.ontario.ca/fr/page/declaration-concernant-la-protection-de-la-vie-privee",
			},
			contact: "Nous joindre",
			help: "Aide",
			copyright: {
				title: "© Imprimeur du Roi pour l’Ontario, ",
				url: "https://www.ontario.ca/fr/page/droits-dauteur",
			},
		},
	},
	contact: {
		english: {
			header: "My Ontario Account",
			subHeader: "Contact us",
			paragraph:
				"For help with this online service or to report a problem, please contact the Ontario Government service that you are trying to access.",
			title: "My Ontario Account Helpdesk",
			local: "Local:",
			localNumber: "123-456-7890",
			tollFree: "Toll-free:",
			tollFreeNumber: "123-456-7890",
			email: "Email:",
			emailAddress: "publicsecure@ontario.ca",
			schedule:
				"Monday to Friday \n8:30 a.m. to 5:00 p.m. (EST), excluding holidays.",
			feedback: "Have feedback or a suggestion?",
			feedbackLink: {
				title: "Email Us!",
			},
		},
		french: {
			header: "Compte Mon Ontario",
			subHeader: "Nous joindre",
			paragraph:
				"Pour obtenir de l’aide concernant le service en ligne ou pour signaler un problème, communiquez avec le service du gouvernement de l’Ontario auquel vous tentez d’accéder.",
			title: "Service d’assistance Compte Mon Ontario",
			local: "Local :",
			localNumber: "123-456-7890",
			tollFree: "Numéro sans frais :",
			tollFreeNumber: "123-456-7890",
			email: "Adresse courriel :",
			emailAddress: "publicsecure@ontario.ca",
			schedule:
				"Du lundi au vendredi \nDe 8 h 30 à 17 h (HNE), à l’exception des jours fériés.",
			feedback:
				"Vous avez des commentaires ou des suggestions à nous transmettre?",
			feedbackLink: {
				title: "Écrivez-nous!",
			},
		},
	},
	terms: {
		english: {
			header: "My Ontario Account",
			subHeader: "Terms and conditions of use",
			title1: "Terms of use",
			intro1:
				"By using My Ontario Account, you agree to the following terms of use:",
			terms1:
				"You understand and accept that you are at all times responsible for protecting your email/username and password.",
			terms2:
				"If you suspect that others have obtained them, you are responsible for either changing your information or asking that your connected services revoke your access privileges. If you choose the latter, you will have to re-register with your connected services and My Ontario Account.",
			terms3:
				"You understand and accept that the Government of Ontario can revoke your My Ontario Account access for security or administrative reasons.",
			terms4:
				"You understand and accept that your My Ontario Account will be deleted from the government system if no ministry program is associated with your account within sixty (60) days of your account registration date.",
			terms5:
				"You understand and accept that a record of your registration will be kept in accordance with the ",
			terms5_link: {
				url: "https://www.ontario.ca/laws/statute/06a34",
				title: "Archives and Record Keeping Act",
			},
			terms5_1:
				" even if you choose to delete your account. Your account will be removed permanently seven years after you chose to delete it.",
			outro1:
				"Each government service that uses My Ontario Account may have service-specific Terms of Use. Please refer to each service’s web page for details.",
			title2: "Warranty disclaimer",
			warranty1:
				"You understand and accept that the Government of Ontario disclaims all liability (except in cases of gross negligence or wilful misconduct) in relation to the use of, delivery of or reliance upon My Ontario Account.",
			warranty2:
				'Use of this website and its contents is at your own risk. This website and all of its content are provided "As Is". You must not rely on any content published on or linked to this website without first making your own enquiries to verify it is accurate, current and complete. We do not make, and expressly disclaim, any representations, warranties, covenants and conditions, express or implied, arising out of operation of law, course of performance, course of dealing, usage of trade or otherwise, including any express or implied warranties and conditions of: merchantability; merchantable quality; fitness for a particular purpose; non-infringement; safety; quality; freedom from defects or that defects will be corrected; uninterrupted, virus-free or error-free use of this website; or accuracy, currency, reliability, completeness or appropriateness relating to the content of this website.',
			title3: "Notice of collection",
			notice1:
				"We are collecting your personal information to create and manage your My Ontario Account. This information does not identify you by name; however, it does identify you by the email address that you provide.",
			notice2:
				"The information you provide to establish a My Ontario Account will be used to enable you to connect to Ontario services you’re authorized to access. If you forget and need to recover your password, a notification will be sent to your email address to assist you. If your account information changes (e.g. change in password), an email will be sent to notify you of this change. We may share your email address with participating Ontario Ministries that administer applicable programs for review if your email address is not associated with any Ontario Service. We may use your email address in Ontario Ministry internal reports. Your email address will not be used or disclosed for any other purpose.",
			notice3:
				"The Ministry of Government and Consumer Services is authorized to collect your personal information under the authority of section 6(a) and 6(b) of the Ministry of Government Services Act, as this information is necessary to properly administer your My Ontario Account.",
			closing:
				"If you have any questions about the collection, use and disclosure of your personal information, please contact: Program Director, Identity and Access Management, 4th Floor, 222 Jarvis St, Toronto, ON M7A 0B6. Telephone: 416-212-9004.",
		},
		french: {
			header: "Compte Mon Ontario",
			subHeader: "Modalités d’utilisation",
			title1: "Conditions d’utilisation",
			intro1:
				"En utilisant compte Mon Ontario, vous acceptez les conditions d’utilisation suivantes :",
			terms1:
				"Vous comprenez et acceptez que vous êtes en tout temps responsable de protéger votre courriel, votre nom d’utilisateur et votre mot de passe.",
			terms2:
				"Si vous soupçonnez que d’autres personnes les ont obtenus, vous devez modifier vos renseignements ou demander à vos services connectés de révoquer vos privilèges d’accès. Si vous choisissez cette dernière option, vous devrez vous inscrire de nouveau auprès de vos services connectés et compte Mon Ontario.",
			terms3:
				"Vous comprenez et acceptez que le gouvernement de l’Ontario peut révoquer votre accès à compte Mon Ontario pour des raisons de sécurité ou administratives.",
			terms4:
				"Vous comprenez et acceptez que votre compte Mon Ontario sera supprimé du système du gouvernement si aucun programme ministériel n’y est associé dans les soixante (60) jours suivant la date d’inscription de votre compte.",
			terms5:
				"Vous comprenez et acceptez qu’un enregistrement de votre inscription sera gardé conformément à la ",
			terms5_link: {
				url: "https://www.ontario.ca/laws/statute/06a34",
				title:
					"Loi de 2006 sur les Archives publiques et la conservation des documents",
			},
			terms5_1:
				", et ce, même si vous décidez de supprimer votre compte. Votre compte sera éliminé de façon permanente sept ans après que vous l’ayez supprimé.",
			outro1:
				"Chaque service public qui utilise compte Mon Ontario peut avoir ses propres conditions d’utilisation. Veuillez consulter la page Web de chaque service pour connaître les détails.",
			title2: "Exonération de garantie",
			warranty1:
				"Vous comprenez et acceptez que le gouvernement de l’Ontario n’assume aucune responsabilité (sauf dans les cas de négligence grave ou de faute intentionnelle) quant à l’utilisation et la prestation compte Mon Ontario et la confiance qui lui est accordée.",
			warranty2:
				"L’utilisation de ce site Web et de son contenu se fait à vos propres risques. Ce site Web et l’ensemble de son contenu sont fournis « en l’état ». Vous ne pouvez pas vous fier à tout contenu publié sur ce site Web ou lié à ce dernier sans d’abord faire vos propres recherches pour vous assurer qu’il est exact, complet et à jour. Nous ne faisons pas, et rejetons expressément, les représentations, garanties, engagements et conditions, expresses ou implicites, découlant de l’application de la loi, de l’exécution, des pratiques commerciales, de l’usage du commerce ou autre, y compris toute garantie et condition expresse ou implicite sur la qualité marchande, l’adéquation à un usage particulier, l’absence de contrefaçon, la sécurité, la qualité, l’absence de défauts ou la correction des défauts, l’utilisation ininterrompue, sans virus ou sans erreur de ce site Web ou l’exactitude, l’actualité, la fiabilité, l’exhaustivité ou la pertinence du contenu de ce site Web.",
			title3: "Avis de collecte de renseignements",
			notice1:
				"Nous recueillons vos renseignements personnels pour créer et gérer votre compte Mon Ontario. Ces renseignements ne permettent pas de vous identifier par votre nom, mais par l’adresse électronique que vous avez fournie.",
			notice2:
				"Les renseignements que vous fournissez pour configurer un compte Mon Ontario seront utilisés pour vous permettre d’accéder à des services de l’Ontario auxquels vous êtes autorisé à accéder. Si vous oubliez votre mot de passe et que vous devez le réinitialiser, une notification vous sera envoyée par courriel pour vous aider. Si les renseignements à votre compte changent (comme votre mot de passe), un courriel vous sera envoyé pour vous informer de ce changement. Vous pouvez utiliser votre adresse courriel dans les rapports internes du ministère ontarien. Votre adresse courriel ne sera pas utilisée ou divulguée à d’autres fins.",
			notice3:
				"Le ministère des Services gouvernementaux et des Services aux consommateurs est autorisé à recueillir vos renseignements personnels en vertu des paragraphes 6(a) et 6(b) de la Loi sur le ministère des Services gouvernementaux, car ces renseignements sont nécessaires pour bien administrer votre compte Mon Ontario.",
			closing:
				"Si vous avez des questions au sujet de la collecte, de l’utilisation et de la divulgation de vos renseignements personnels, veuillez communiquer avec le Directeur des programmes, gestion de l'identité et de l'accès, 4e étage, 222, rue Jarvis, Toronto (Ontario) M7A 0B6. Téléphone : 416 212-9004.",
		},
	},
};

export default text;
