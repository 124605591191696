import { createTheme } from "@mui/material";

export const theme = createTheme({
	typography: {
		color: "#1A1A1A",
		//fontFamily: '"Raleway", "Open Sans", "Arial", sans-serif', // specifying a new font
		button: {
			//fontFamily: '"Raleway", "Open Sans", "Arial", sans-serif',
			textTransform: "none",
		},
	},
	palette: {
		alert: { main: "#CD0000" },
		green: { main: "#2B8737" },
		greyscale70: { main: "#4D4D4D" },
		link: { main: "#0066cc" },
		linkHover: { main: "#00478F" },
		linkVisited: { main: "#551A8B" },
		primary: { main: "#333" },
		secondary: { main: "#06C" },
		systemBlack: { main: "#1A1A1A" },
		warning: { main: "#FFD440" },
	},
});
