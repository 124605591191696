import { useEffect, useContext, useState } from "react";
import Navbar from "./common/Navbar";
import Mainpage from "./Mainpage";
import Footer from "./common/Footer";
import { Context } from "../context/Context";
import { StateContext } from "../App";
import { useIdleTimer } from "react-idle-timer";
import { Box } from "@mui/material";
import SignOutModal from "./SignOutModal";

const classes = {
	home: {
		width: "100%",
		minHeight: "100vh",
		display: "flex",
		justifyContent: "flex-start",
		alignItems: "center",
		flexDirection: "column",
		position: "relative",
	},
};

function Home() {
	const { setLocale } = useContext(Context);
	const { state, dispatch } = useContext(StateContext);
	const [showModal, setShowModal] = useState(false);

	useEffect(() => {
		const userLang = navigator.language || navigator.userLanguage;
		if (userLang === "fr" || userLang === "fr-CA") {
			setLocale("fr");
		}
	}, []);

	useEffect(() => {
		!state.screen && dispatch({ type: "CHANGE_SCREEN", payload: "EPS" });
	}, []);

	const timeout = 600000; //ms

	const handleOnIdle = () => {
		setShowModal(true);
	};

	const keepMe = () => {
		reset();
		setShowModal(false);
	};

	const { reset } = useIdleTimer({
		timeout,
		onIdle: handleOnIdle,
		crossTab: {
			emitOnAllTabs: true,
		},
	});

	return (
		<Box sx={classes.home}>
			<Navbar />
			<Mainpage />
			<Footer />
			{showModal && <SignOutModal showModal={showModal} keepMe={keepMe} />}
		</Box>
	);
}

export default Home;
