import { useContext } from "react";
import {
	Box,
	Dialog,
	DialogContent,
	DialogTitle,
	IconButton,
	makeStyles,
	Typography,
	Divider,
	Grid,
	Link,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import { Context } from "../../context/Context";
import text from "../../data/text";

const classes = {
	boldText: {
		fontWeight: "900",
	},
	closeButton: {
		position: "absolute",
		top: "24px",
		right: "24px",
	},
	dialogContent: {
		padding: "4%",
		paddingTop: "2%",
	},
	dialogTitle: {
		position: "relative",
		padding: "4%",
		paddingBottom: "2%",
	},
	divider: {
		height: "4px",
		width: "92%",
		margin: "auto",
	},
	header: {
		fontSize: "1.75rem",
		letterSpacing: ".02rem",
		lineHeight: "1.39",
		marginBottom: "1rem",
		fontWeight: "700",
	},
	listItem: {
		marginTop: "8px",
	},
	outro1: {
		marginBottom: "16px",
	},
	subHeader: {
		fontSize: "1.5rem",
		letterSpacing: ".03rem",
		lineHeight: "1.5",
		fontWeight: "700",
	},
	termsLink: {
		color: "link.main",
		textDecoration: "underline",
		"&:visited": {
			color: "linkVisited.main",
		},
		"&:hover": {
			color: "linkHover.main",
			textDecoration: "underline",
		},
	},
	title: {
		marginBottom: "20px",
		fontSize: "22px !important",
	},
};

function Terms(props) {
	const { locale } = useContext(Context);
	const language = locale === "fr" ? "french" : "english";
	return (
		<Dialog
			open={props.showModal}
			onClose={() => props.closeModal()}
			fullWidth={true}
			maxWidth={"md"}
			scroll="body"
		>
			<DialogTitle sx={classes.dialogTitle}>
				<img
					src="https://op3static.oktacdn.com/fs/bco/1/fs0ikh3u6aus6gh0j1d6"
					alt="Ontario"
				/>
				<Typography variant="h3" sx={classes.header}>
					{text.terms[language].header}
				</Typography>
				<Typography variant="h4" sx={classes.subHeader}>
					{text.terms[language].subHeader}
				</Typography>
				<IconButton
					size="small"
					sx={classes.closeButton}
					onClick={() => props.closeModal()}
					aria-label="Close Terms"
				>
					<CloseIcon fontSize="small" />
				</IconButton>
			</DialogTitle>
			<Divider sx={classes.divider} variant="middle" />
			<DialogContent sx={classes.dialogContent}>
				<Typography sx={{ ...classes.title, ...classes.boldText }}>
					{text.terms[language].title1}
				</Typography>

				<Box>
					<Typography>{text.terms[language].intro1}</Typography>
					<ul>
						<li style={classes.listItem}>
							<Typography>{text.terms[language].terms1}</Typography>
						</li>
						<li style={classes.listItem}>
							<Typography>{text.terms[language].terms2}</Typography>
						</li>
						<li style={classes.listItem}>
							<Typography>{text.terms[language].terms3}</Typography>
						</li>
						<li style={classes.listItem}>
							<Typography>{text.terms[language].terms4}</Typography>
						</li>
						<li style={classes.listItem}>
							<Typography>
								{text.terms[language].terms5}
								<Link
									href={text.terms[language].terms5_link.url}
									sx={classes.termsLink}
									target="_blank"
									rel="noreferrer noopener"
								>
									{text.terms[language].terms5_link.title}
								</Link>
								{text.terms[language].terms5_1}
							</Typography>
						</li>
					</ul>
					<Typography sx={classes.outro1}>{text.terms[language].outro1}</Typography>
				</Box>

				<Typography sx={{ ...classes.title, ...classes.boldText }}>
					{text.terms[language].title2}
				</Typography>
				<Box>
					<ul>
						<li style={classes.listItem}>
							<Typography>{text.terms[language].warranty1}</Typography>
						</li>
						<li style={classes.listItem}>
							<Typography>{text.terms[language].warranty2}</Typography>
						</li>
					</ul>
				</Box>

				<Typography sx={{ ...classes.title, ...classes.boldText }}>
					{text.terms[language].title3}
				</Typography>
				<Box>
					<ul>
						<li style={classes.listItem}>
							<Typography>{text.terms[language].notice1}</Typography>
						</li>
						<li style={classes.listItem}>
							<Typography>{text.terms[language].notice2}</Typography>
						</li>
						<li style={classes.listItem}>
							<Typography>{text.terms[language].notice3}</Typography>
						</li>
					</ul>
				</Box>
				<Typography>{text.terms[language].closing}</Typography>
			</DialogContent>
		</Dialog>
	);
}

export default Terms;
